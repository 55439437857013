.mbottommin35 {
  margin-bottom: -35px;
}

.fullWidthcontiner {
  width: 100%;
}

.bgCover {
  //  background: url(../../../assets/images/dummy2.jpg) no-repeat center center;
  // background: url(./assets/images/dummy2.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: top top;
}

.h500 {
  height: 500px;
}

.tem52svg1 svg {
  height: 300px;
  position: absolute;
  float: right;
  left: 0;
  transform: rotate(118deg);
  margin-left: 80px;
  margin-top: -190px;
  /* overflow: hidden; */
  width: 200px;
}

.filpimage {
  transform: scaleX(-1);
}

.dispayFlex {
  display: flex;
}

.col4 {
  margin: 10px;
  width: 33.5%;
}

.img03 img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.tem52svg2 svg {
  margin-top: 200px;
  height: 300px;
  position: absolute;
  float: right;
  left: 0;
  transform: rotate(118deg);
  margin-left: 80px;
  margin-top: -190px;
  /* overflow: hidden; */
  width: 200px;
}

.justifyCenter {
  justify-content: center;
  text-align: center;
  align-items: center;
}

.img04 img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.mtop100 {
  margin-top: 100px;
}

.m10 {
  margin: 10px;
}

.col6 {
  width: 50%;
}

.img1 img {
  width: 450px;
  height: 500px;
  object-fit: cover;
}

.img1 img {
  width: 600px;
  height: 600px;
  object-fit: cover;
}

.temp59 {
  transform: rotate(-45deg);
  width: 300px !important;
  height: 300px !important;
  object-fit: cover;
}

.fldirectioncol {
  flex-direction: column;
}

.temp60firsthalf {
  height: 50vh;
  width: 100%;
}

.temp60img {
  position: absolute;
  border-radius: 50%;
  margin-top: 30px;
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.temp60secondhalf {
  height: 50vh;
  background-color: #e1af5e;
  width: 100%;
}

.temp62firsthalf {
  height: 60vh;
  width: 100%;
}

.temp62secondhalf {
  height: 40vh;
  background-color: #e1af5e;
  width: 100%;
}

.goldbgcolor {
  cursor: pointer;
}

.temp62img {
  /* position: absolute; */
  margin-top: -160px;
}

.responsiveImage img {
  width: 100%;
  height: auto;
}

.imageRadius {
  border-radius: 50%;
}

.img01 img {
  width: 500px;
  height: 500px;
  object-fit: cover;
  z-index: 2;
  position: sticky;
}

.border15px {
  border: 15px solid #27aae1;
}

.loveJoyfont {
  font-family: "Dancing Script", cursive;
  font-size: 80px;
}

.loveJoyfont {
  font-size: 60px;
}

.pleft100 {
  padding-left: 100px;
}

.millerstext {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 1.5;
}

.rowReverse {
  flex-direction: row-reverse;
}

.circleImage {
  width: 500px;
  height: 500px;
}

.circleImage::before {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 219px;
  border-left-width: 0px;
  border-bottom: 246px solid #27aae1;

  content: "";
  position: absolute;
  margin-left: 1px;
  margin-top: 280px;
  z-index: -1;
}

.circlefillcolor {
  cursor: pointer;
}

.pright100 {
  padding-right: 100px;
}

.textAlignLeft {
  text-align: left;
}

.temp3pleft {
  padding-left: 100px;
}

.temp3loveJoyfont {
  font-family: "Dancing Script", cursive;
  font-size: 50px;
}

// .temp3millerstext {
//   font-family: "Poppins", sans-serif;
//   font-size: 40px;
//   text-transform: uppercase;
//   text-align: left;
// }
.temp3millerstext {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  text-align: left;
}

.img02 img {
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.mlr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.brownColor {
  background-color: #e1af5e;
}

.brownTxtHeader {
  font-family: "Conv_MoonTime-Regular";
  text-align: center;
  font-size: 128px;
  padding: 40px 10px;
}

.plr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.mtopmin35 {
  margin-top: -35px;
}

.obFit {
  object-fit: cover;
}

.fldirectioncolrev {
  flex-direction: column-reverse;
}

.template9header {
  margin-top: -100px;
  z-index: 99;
}

.mtop50 {
  margin-top: 50px;
}

.cilcleImg {
  border-radius: 50%;
  cursor: pointer;
  /* box-shadow: 0 0 0 9px #fffefd, 0 0 0 10px #fff, 0 0 0 16px #e1af5e, 0 0 0 18px #e1af5e; */
}

.cilcleImg img {
  border-radius: 50%;
  width: 300px;
  box-shadow: 0 0 0 9px #ffffff, 0 0 0 10px #fff, 0 0 0 16px #e1af5e,
    0 0 0 18px #e1af5e;
  height: 300px;
  object-fit: cover;
}

.mtopmin80 {
  margin-top: -80px;
}

.mleftmin100 {
  margin-left: -100px;
}

.mrightmin100 {
  margin-right: -100px;
}

.arrowsvg {
  transform: rotate(45deg);
  width: 300px;
  margin-left: -44px;
  position: absolute;
  margin-top: -100px;
  z-index: 9;
}

.template16header {
  z-index: 99;
  font-size: 80px;
  position: relative;
  font-family: "Conv_MoonTime-Regular";
  bottom: -150px;
  line-height: 60px;
}

.arrowsvgleft {
  transform: rotate(-45deg);
  width: 300px;
  margin-left: 246px;
}

.textalignRight {
  text-align: right;
}

.template20header {
  z-index: 99;
  font-size: 130px;
  font-family: "Conv_MoonTime-Regular";
}

.overflowHidden {
  overflow: hidden;
}

.mrightmin60 {
  margin-right: -60px;
}

.millerstext2 {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-top: -86px;
  position: absolute;
  margin-left: 70px;
}

.ptop50 {
  padding-top: 50px;
}

.mleftmin60 {
  margin-left: -60px;
}

.justifyCenter2 {
  justify-content: center;
  text-align: center;
  align-items: center;
}

.tem27 img {
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.tem27border {
  border: 5px solid #7c747c;
  width: 480px;
  height: 480px;
  position: absolute;
  margin-top: -480px;
  margin-left: 30px;
  z-index: 3;
}

.tem27draw svg {
  width: 150px;
  position: absolute;
  margin-top: -530px;
  margin-left: -300px;
  z-index: 2;
}

.bulletstyle {
  text-align: left;
  margin-left: 80px;
  font-family: "Montserrat", sans-serif;
}

.bulletstyle ul li {
  list-style: none;
  font-family: "Montserrat", sans-serif;
}

.bulletstyle ul li::before {
  list-style: none;
}

.bulletstyle ul li::before {
  content: "•";
  color: #000;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-family: "Montserrat", sans-serif;
}

.temp32header {
  position: relative;
  z-index: 5;
}

.mleftmin40 {
  margin-left: -40px;
}

.mleftmin115 {
  margin-left: -115px !important;
}

.tem34header2 {
  z-index: 99;
  font-size: 130px;

  font-family: "Conv_MoonTime-Regular";
  position: absolute;
  margin-top: -60px;
  margin-left: 72px;
}

.tem34header {
  position: absolute;
  width: 65%;
  margin-top: -70px;
}

.col12 {
  margin: 10px;
  width: 100%;
}

.margin0 {
  margin: 0 !important;
}

.tem34headerText {
  z-index: 99;
  font-size: 130px;
  font-family: "Conv_MoonTime-Regular";
}

.tem35text {
  position: relative;
  z-index: 5;
}

.tem35header {
  position: absolute;
  width: 100%;
  margin-top: -160px;
  z-index: -1;
}

.h600 {
  height: 600px;
}

.contactmeButton {
  position: absolute;
  background-color: #e1af5e;
  border-radius: 25px;
  padding: 10px 30px;
  color: #000;
  font-size: 40px;
  font-family: "Conv_MoonTime-Regular";
  float: right;
  right: 0;
  margin-top: 10%;
  margin-right: 20%;
  cursor: pointer;
}

.margin100 {
  margin: 100px;
}

.mainTitle {
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: "Montserrat", sans-serif;
}

.pbottom40 {
  padding-bottom: 40px;
}

.maincontent {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  line-height: 30px;
}

.textAligncenter {
  text-align: center;
}




.maincontentBullet ul {
  display: inline-block;
  text-align: left;
}

.maincontentBullet ul li {
  list-style: none;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 10px;
}

.maincontentBullet ul li::before {
  list-style: none;
}

.maincontentBullet ul li::before {
  content: "•";
  color: #000;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-family: "Montserrat", sans-serif;
}

.mtop200 {
  margin-top: 200px;
}

.srtipbgcolor {
  height: 20px;
  background-color: #e1af5e;
}

.tem37headerText {
  z-index: 99;
  font-size: 180px;
  font-family: "Conv_MoonTime-Regular";
}

.temptextBlock {
  position: absolute;
  margin-top: -345px;
  float: right;
  right: 0;
  margin-right: 5%;
}

.tem39headerText {
  z-index: 99;
  font-size: 150px;
  font-family: "Conv_MoonTime-Regular";
}

.tem39innertxt {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 80px;
  font-size: 45px;
  text-transform: uppercase;
  letter-spacing: 12px;
  position: absolute;
  margin-top: -50px;
  margin-left: -70px;
}

.temp40textBlock {
  position: absolute;
  margin-top: -345px;
  margin-left: 15%;
}

.col1 {
  width: 10%;
}

.col11 {
  width: 90%;
}

.srtipbgcolor2 {
  height: 7px;
  background-color: #e1af5e;
}

.tem41righttxt {
  position: absolute;
  float: right;
  right: 0;
  margin-top: -115px;
  /* margin-right: 15%; */
  background: #fff;
  padding: 0px 40px;
}

.tem41headerText {
  z-index: 99;
  font-size: 180px;
  font-family: "Conv_MoonTime-Regular";
}

.temp41Formtext {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 4px;
  position: absolute;
  z-index: 99;
  padding-top: 35px;
  margin-left: 80px;
  float: right;
  right: 0;
  margin-right: 19%;
}

.temp41 svg {
  margin-top: -7px;
  margin-left: -20px;
}

.tem43text {
  font-family: "Conv_MoonTime-Regular";
  text-align: center;
  font-size: 100px;
  padding: 0px 130px;
}

.tem44text {
  font-family: "Conv_MoonTime-Regular";
  text-align: center;
  font-size: 100px;
}

.pullRight {
  text-align: right !important;
}

.tem44millerstext {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 6px;
  margin-top: -86px;
  position: absolute;
  margin-left: 70px;
}

.pullLeft {
  text-align: left !important;
}

.tem46righttxt {
  position: absolute;
  float: right;
  right: 0;
  margin-top: -123px;
  margin-right: 6%;
}

.tem47righttxt {
  position: absolute;

  margin-top: -123px;
  margin-left: 6%;
}

.tem48headerText {
  z-index: 99;
  font-size: 180px;
  font-family: "Conv_MoonTime-Regular";
}

.tem49millerstext {
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  text-transform: uppercase;
  margin-top: -60px;
  margin-left: -199px;
  letter-spacing: 6px;
}

.tem50strip {
  height: 200px;
  width: 8px;
  align-items: center;
}

.mright100 {
  margin-right: 100px;
}

.mleft100 {
  margin-left: 100px;
}

.tem51millerstext {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  text-transform: uppercase;

  letter-spacing: 6px;
  text-align: left;
  line-height: 100px;
}

.tem51headerText {
  z-index: 99;
  font-size: 120px;
  font-family: "Conv_MoonTime-Regular";
  margin-top: 70px;
  position: relative;
  margin-left: -20px;
}

.tem51srtip {
  height: 8px;
  position: absolute;
  width: 420px;
  margin-top: -40px;
  margin-left: -80px;
}




.blueCircle {
  width: 520px;
  height: 520px;
  background: #27aae1;
  border-radius: 50%
}

.bluearrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 227px 0 0 226px;
  border-color: transparent transparent transparent #27aae1;
  position: absolute;
  margin-top: 291px;
  margin-left: 2px;
}

.blueCircleBlock {
  width: 550px
}

.circleImg {
  width: 480px;
  height: 480px;
  margin-top: -502px;
  position: absolute !important;
  margin-left: -255px;
}

.spaceEvenly {
  justify-content: space-evenly !important;
}

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

.dummytopImage img {

  width: 100%;
  height: 500px;
}

.mtopmin500 {

  margin-top: -500px;
}



.postionrelative {
  position: relative;
}


.maincircleImg {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto
}

.innerCircle {
  border-radius: 50%;
  width: 300px;
  height: 300px;
  border: 10px solid #e1af5e;
}

.innerImage {
  border-radius: 50%;
  width: 270px;

  height: 270px;
  margin-top: -325px;
  /* position: absolute; */
  // margin-left: 21px;
}


.template12header {
  z-index: 99;
  font-size: 80px;
  position: absolute;
  font-family: "Conv_MoonTime-Regular";
}

.mtopmin80 {
  margin-top: -61px;
  margin-left: 20px;
}



@font-face {
  font-family: "Conv_MoonTime-Regular";
  src: url("fonts/MoonTime-Regular.eot");
  src: local("?"),
    url("fonts/MoonTime-Regular.woff") format("woff"),
    url("fonts/MoonTime-Regular.ttf") format("truetype"),
    url("fonts/MoonTime-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "melattiregular";
  src: url("fonts/melatti-webfont.woff2") format("woff2"),
    url("fonts/melatti-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}




.t32cilcleImg {
  border-radius: 50%;
  /* box-shadow: 0 0 0 9px #fffefd, 0 0 0 10px #fff, 0 0 0 16px #e1af5e, 0 0 0 18px #e1af5e; */
  position: sticky;
  z-index: 8;
}

.t32cilcleImg img {
  border-radius: 50%;
  /* width: 250px; */
  box-shadow: 0 0 0 9px #ffffff, 0 0 0 10px #fff, 0 0 0 16px #e1af5e,
    0 0 0 18px #e1af5e;
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.mrightmin40 {
  margin-right: -40px;
}

.millerstext3 {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: -77px;
  position: relative;
  margin-left: -184px;
}

.tem42righttxt {
  position: absolute;
  /* float: right;
        right: 0; */
  margin-top: -115px;
  /* margin-right: 15%; */
  background: #fff;
  padding: 0px 40px;
}

.temp42Formtext {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 4px;

  position: absolute;
  z-index: 99;
  padding-top: 65px;
  margin-left: 38px;

  margin-right: 19%;
}

.col9 {
  width: 70%;
}

.temp42 svg {
  margin-top: -7px;
  margin-right: 100px;
}

.temp63img {
  /* position: absolute; */

  margin-top: -240px;
  margin-left: 50px;
  margin-right: 50px;
}

.h400 {
  height: 400px;
}

.p100 {
  padding: 100px;
}

.temp74 {
  font-family: "melattiregular";
  font-size: 80px;
  padding: 20px;
  color: #fff;
  text-align: center;
}

.temp75 {
  color: #fff !important;
}

.maincontentBulletwhite ul {
  display: inline-block;
  text-align: left;
}

.maincontentBulletwhite ul li {
  list-style: none;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 10px;
}

.maincontentBulletwhite ul li::before {
  list-style: none;
}

.maincontentBulletwhite ul li::before {
  content: "•";
  color: #fff;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-family: "Montserrat", sans-serif;
}

.h20 {
  height: 20px;
}

.mbotton50 {
  margin-bottom: 50px;
}

.plt50 {
  padding: 0px 50px;
}

.temp76 {
  padding-top: 50px;
  padding-left: 80px;
}

.loveJoyfont2 {
  font-family: "Dancing Script", cursive;
  font-size: 80px;
}

.circleImage2 {
  width: 400px;
  height: 400px;
  margin-top: 50px;
  position: sticky;
}

.circleImage2 img {
  width: 400px;
  height: 400px;
  object-fit: cover;
}

.circleImage2::before {
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-top-width: 0px;
  border-bottom-width: 200px;
  border-right: 173px solid #27aae1;
  content: "";
  position: absolute;
  margin-left: 228px;
  margin-top: 2px;
  z-index: -1;
}

.cilcleImg3 {
  border-radius: 50%;
}

.cilcleImg3 img {
  border-radius: 50%;
  width: 400px;
  box-shadow: 0 0 0 9px #ffffff, 0 0 0 10px #fff, 0 0 0 16px #e1af5e,
    0 0 0 18px #e1af5e;
  margin-left: 50px;
  height: 400px;
  object-fit: cover;
}

.temp80 {
  padding-left: 100px;
  padding-right: 80px;
  width: 65%;
}

.temp81cilcleImg3 {
  border-radius: 50%;
  /* box-shadow: 0 0 0 9px #fffefd, 0 0 0 10px #fff, 0 0 0 16px #e1af5e, 0 0 0 18px #e1af5e; */
}

.temp81cilcleImg3 img {
  border-radius: 50%;
  width: 400px;
  box-shadow: 0 0 0 9px #ffffff, 0 0 0 10px #fff, 0 0 0 16px #e1af5e,
    0 0 0 18px #e1af5e;
  margin-right: 50px;
  height: 400px;
  object-fit: cover;
}

.tem37righttxt {
  position: absolute;
  float: right;
  right: 0;
  margin-top: -155px;
  margin-right: 15%;
}

.mbottom10 {
  margin-bottom: 10px;
}

.temp84secondimage img {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.col8 {
  margin: 10px;
  width: 66.666667%;
}

.tem38lefttxt {
  position: absolute;
  /* float: right;
        right: 0; */
  margin-top: -155px;
  margin-left: 2%;
}

.mb50 {
  margin-bottom: 50px;
}

.temp88img {
  margin-left: 5%;
  position: absolute;
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.temp88img img {
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.tem88border {
  border: 5px solid #e1af5e;
  width: 430px;
  height: 500px;
  position: absolute;
  margin-top: -480px;
  margin-left: 30px;
  z-index: 3;
}

.temp88 {
  padding-left: 80px;
}

.h5 {
  height: 5px;
}

.temp88strip {
  width: 85%;
  float: right;
  margin-top: -22px;
}

.temp89strip {
  width: 85%;
  /* float: right; */
  margin-top: -22px;
  margin-left: -100px;
}

.tem89border {
  border: 5px solid #e1af5e;
  width: 480px;
  height: 500px;
  position: absolute;
  margin-top: -480px;
  margin-left: -30px;
  z-index: 3;
}

.pleft15per {
  padding-left: 15%;
}

.h280 {
  height: 280px;
  margin-left: 80px;
}

.temp95 {
  transform: rotate(45deg);
  width: 200px !important;
  height: 200px;
  object-fit: cover;
}

.flexFlow {
  flex-flow: row wrap;
}

.imgthumb img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

// Media Query start
@media (max-width: 767px) {
  .mbottommin35 {
    margin-bottom: 0px !important;
  }

  img.dummytopImage {
    width: 100%;
  }

  .img1 img {
    width: 100%;
  }

  .mtop100 {
    margin-top: 30px;
  }

  .fullWidthcontiner {
    padding: 0px 16px;
    margin: 24px 0px !important;
  }

  .m10 {
    margin: 0px;
  }

  .img04 {
    width: 100%;
    margin-bottom: 16px;
  }

  .col4 {
    margin-bottom: 16px;
  }

  .col3 {
    margin-bottom: 16px !important;
  }

  .col6 {
    margin-bottom: 16px;
  }

  .fullWidthcontiner.height100vh.justifyCenter.dispayFlex.mtop100>div {
    width: 100%;
  }

  .dummytopImage {
    margin-bottom: 16px;
  }

  .dummytopImage img {
    height: 250px;
  }

  .h500 {
    height: auto;
  }

  .bgCover {
    margin-bottom: 16px;
  }

  .temp59 {
    width: 100% !important;
    height: 100% !important;
    transform: rotate(0deg);
    margin-bottom: 16px;
  }

  .temp60img {
    width: 200px;
    height: 200px;
    transform: translateX(-50%);
    margin-top: 0px;
  }

  .temp60firsthalf {
    height: 20vh;
  }

  .temp60secondhalf {
    height: 150px;
  }

  .blueCircleBlock {
    width: 100%;
  }

  .blueCircle {
    width: 240px;
    height: 240px;
    margin: auto;
  }

  .circleImg {
    width: 210px;
    height: 210px;
    margin-top: -266px;
    margin-left: auto;
    margin-right: auto;
    position: unset !important;
  }

  .bluearrow {
    border-width: 135px 0 0 125px;
    position: absolute;
    margin-top: 105px;
    margin-left: 1px;
    z-index: -1;
  }

  .millerstext {
    margin-bottom: 24px;
  }

  .temp3pleft {
    padding-left: 0px !important;
    padding-top: 0px !important;
  }

  .mtopmin500 {
    margin-top: -260px;
  }

  .mlr15 {
    margin: 0px 0px;
  }

  .plr15 {
    padding: 0px 0px;
  }

  .marBottom {
    margin-bottom: 24px !important;
  }

  .template12header {
    position: unset;
  }

  .mtopmin80 {
    margin-top: 0px;
    margin-left: 0px;
  }

  .img01 img {
    width: 100% !important;
    height: auto !important;
  }

  .mtop50 {
    margin-top: 0px;
  }

  .template16header {
    bottom: 0px;
    font-size: 50px;
    line-height: 1.5;
  }

  .arrowsvgleft {
    width: 70px;
  }

  .arrowsvg {
    width: 70px;
  }

  .mtopmin35 {
    margin-top: 0px;
  }

  .template20header {
    font-size: 50px;
    line-height: 1.5;
    text-align: left !important;
  }

  .ptop50 {
    padding-top: 0px;
  }

  .millerstext2 {
    text-align: center;
    left: 0px;
    right: 0px;
    margin-left: 0px;
    margin-top: -46px;
    font-size: 20px;
  }

  .bulletstyle {
    padding-top: 0px;
    margin-left: 0px;
  }

  .tem27 img {
    margin-left: 30px;
  }

  .tem27border {
    margin-left: 0px;
  }

  .tem34header2 {
    position: relative;
    margin-left: 0px;
    margin-top: 0px;
    text-align: center;
  }

  .tem34header {
    position: absolute;
    left: 0;
    right: 0px;
  }

  .tem34headerText {
    position: relative;
    font-size: 40px;
  }

  .tem35text {
    position: absolute;
    left: 0;
    right: 0px;
    text-align: center;
  }

  .tem35header {
    margin-top: 0px;
    position: unset;
  }

  .margin100 {
    margin: 0px;
  }

  .pbottom40 {
    padding-bottom: 20px;
  }

  .mtop200 {
    margin-top: 20px !important;
  }

  .tem37headerText {
    font-size: 40px;
  }

  .tem43text {
    font-size: 40px;
    padding: 0px 30px;
  }

  .tem44text {
    font-size: 50px;
  }

  .tem50strip {
    height: 80px;
  }

  .tem39headerText {
    font-size: 50px;
  }

  .tem48headerText {
    font-size: 50px;
  }

  .temp40textBlock {
    margin-top: -104px;
  }

  .temptextBlock {
    margin-top: -95px;
  }

  .temp3loveJoyfont {
    font-size: 40px;
  }

  .temp3millerstext {
    font-size: 30px;
  }

  .img02 img {
    height: 240px;
  }


  // new responsive start


  // .cilcleImg3 img {
  //   width: 250px;
  //   height: 250px;
  //   object-fit: cover;
  // }
  // .temp81cilcleImg3 img {
  //   width: 250px;
  //   margin-left: 30px;
  //   height: 250px;
  //   object-fit: cover;
  // }
  // .loveJoyfont2 {
  //   font-size: 40px;
  // }

  // .circleImage2 img {
  //   width: 250px !important;
  //   height: 250px !important;
  // }

  // .circleImage2::before {
  //   border-bottom-width: 129px !important;
  //   border-right: 142px solid #27aae1 !important;
  //   content: "";
  //   margin-left: 109px !important;
  //   margin-top: 0px !important;
  // }
  // .temp62secondhalf{
  //   height: auto;
  // }
  // .cilcleImg3 img {
  //   width: 250px !important;
  //   height: 250px !important;
  // }
  // .tempNo_81 .cilcleImg img{
  //     width: 250px !important;
  //       height: 250px !important;
  // }
  // .temp81cilcleImg3 img {
  //     width: 250px !important;
  //       height: 250px !important;
  // }
  // .tempNo_96 .imgthumb img {
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }
  // tempNo_7
  // tempNo_32
  // tempNo_33
  // tempNo_36
  // tempNo_42
  // tempNo_55
  // tempNo_62
  // tempNo_63
  // tempNo_73
  // tempNo_74
  // tempNo_75
  // tempNo_76
  // tempNo_77
  // tempNo_80
  // tempNo_81
  // tempNo_82
  // tempNo_83
  // tempNo_84
  // tempNo_85
  // tempNo_86
  // tempNo_87
  // tempNo_88
  // tempNo_89
  // tempNo_90
  // tempNo_91
  // tempNo_95
  // tempNo_96






}

@media only screen and (max-width: 900px) {
  .tem52svg1 svg {
    height: 210px;
    margin-left: 80px;
    margin-top: -140px;
  }

  .dispayFlex {
    flex-wrap: wrap;
  }

  .col4 {
    width: 100%;
    margin: 0;
  }

  .img03 img {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }

  .tem52svg2 svg {
    display: none;
  }

  .height100vh {
    height: inherit;
  }

  .col3 {
    width: 100%;
  }

  .col6 {
    width: 100%;
  }

  .img1 img {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }

  .temp59 {
    margin-bottom: 0px;
    width: 200px !important;
    height: 200px !important;
  }

  .circleImage {
    width: 300px;
    height: 300px;
  }

  .circleImage img {
    width: 300px;
    height: 300px;
  }

  .circleImage::before {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-right-width: 200px;
    border-left-width: 0px;
    border-bottom: 200px solid #27aae1;
    content: "";
    position: absolute;
    margin-left: 3px;
    margin-top: 127px;
    z-index: -1;
  }

  .temp3pleft {
    padding-left: 50px;
    padding-top: 27px;
  }

  .img02 img {
    width: 280px;
    height: 280px;
    object-fit: cover;
  }

  .brownTxtHeader {
    font-size: 48px;
  }

  .marBottom {
    margin-bottom: 0px !important;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .mleftmin100 {
    margin-left: 0px;
  }

  .mrightmin100 {
    margin-right: 0px;
  }

  .arrowsvg {
    margin-left: 0px;
  }

  .arrowsvgleft {
    margin-left: 0px;
  }

  .justifyCenter2 {
    justify-content: inherit;
    text-align: inherit;
    align-items: inherit;
  }

  .tem27 img {
    width: 300px;
    height: 300px;
    margin-left: 40px;
  }

  .tem27border {
    width: 280px;
    height: 280px;
    margin-top: -280px;
    margin-left: 60px;
  }

  .tem27draw svg {
    margin-top: -350px;
    margin-left: 0px;
  }

  .bulletstyle {
    padding-top: 50px;
  }

  .tem34header2 {
    font-size: 50px;
    margin-left: 52px;
  }

  .tem34header {
    width: 100%;
  }

  .tem34headerText {
    font-size: 60px;
  }

  .tem35header {
    margin-top: -70px;
  }

  .tem37headerText {
    font-size: 80px;
  }

  .temptextBlock {
    margin-top: -135px;
  }

  .tem39headerText {
    font-size: 80px;
  }

  .tem39innertxt {
    line-height: 21px;
    margin-top: -15px;
    font-size: 20px;
    letter-spacing: 5px;
  }

  .temp40textBlock {
    margin-top: -132px;
  }

  .mLeft10 {
    margin-left: 10px;
  }

  .srtipbgcolor2 {
    height: 4px;
  }

  .tem41righttxt {
    margin-top: -29px;
    padding: 0px 10px;
  }

  .tem41headerText {
    font-size: 40px;
  }

  .temp41Formtext {
    font-size: 12px;
    margin-right: 5%;
    padding-top: 15px;
    margin-left: 0px;
  }

  .temp41 svg {
    width: 100px;
    margin-left: 20px;
  }

  .tem44millerstext {
    font-size: 15px;
    margin-left: 50px;
    margin-top: -45px;
  }

  .tem46righttxt {
    margin-top: -64px;
  }

  .tem47righttxt {
    margin-top: -64px;
  }

  .tem48headerText {
    font-size: 70px;
  }

  .tem49millerstext {
    font-size: 11px;
    margin-top: -20px;
    margin-left: -76px;
    letter-spacing: 2px;
  }

  .mright100 {
    margin-right: 30px;
  }

  .mleft100 {
    margin-left: 0px;
  }

  .tem51millerstext {
    font-size: 20px;
    line-height: inherit;
  }

  .tem51headerText {
    margin-top: 15px;
    font-size: 39px;
  }

  .tem51srtip {
    margin-top: -13px;
    width: 180px;
  }

  .t32cilcleImg img {
    width: 250px;
    height: 250px;
    object-fit: cover;
  }

  .millerstext3 {
    margin-top: 0px;
    margin-left: 0px !important;
  }

  .tem42righttxt {
    margin-top: -29px;
    padding: 0px 10px;
  }

  .temp42Formtext {
    font-size: 12px;
    margin-right: 5%;
    padding-top: 15px;
    margin-left: 10px;
  }

  .temp42 svg {
    width: 100px;
    margin-left: 0px;
    float: right;
    margin-top: 0px;
    margin-right: 0px;
  }

  .p100 {
    padding: 20px;
  }

  .plt50 {
    padding: 0px 10px;
  }

  .temp76 {
    padding-top: 0px;
    padding-left: 0px;
  }

  .loveJoyfont2 {
    font-size: 40px;
  }

  .circleImage2 {
    width: 250px;
    height: 250px;
    margin: auto;
  }

  .circleImage2 img {
    width: 250px;
    height: 250px;
  }

  .circleImage2::before {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-top-width: 0px;
    border-bottom-width: 150px;
    border-right: 100px solid #27aae1;
    content: "";
    position: absolute;
    margin-left: 148px;
    margin-top: 3px;
    z-index: -1;
  }

  .cilcleImg img {
    width: 250px;
    height: 250px;
  }

  .template12header {
    font-size: 80px;
  }

  .cilcleImg3 img {
    width: 250px;
    height: 250px;
    object-fit: cover;
    margin-left: 0px;
  }

  .temp80 {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .temp81cilcleImg3 img {
    width: 250px;
    margin-right: 0px;
    height: 250px;
    object-fit: cover;
  }


  .tem37righttxt {
    margin-top: -63px;
    margin-right: 5%;
  }

  .col8 {
    width: 100%;
  }

  .tem38lefttxt {
    margin-top: -73px;
  }

  .temp88img {
    margin-left: inherit;
    position: inherit;
    width: 100%;
    height: auto;
  }

  .temp88img img {
    width: 300px;
    height: 300px;
  }

  .tem88border {
    width: 280px;
    height: 300px;
    margin-top: -280px;
    margin-left: 60px;
  }

  .tem89border {
    width: 280px;
    height: 300px;
    margin-top: -280px;
    margin-left: 60px;
  }

  .temp84firstimage img {
    width: 100%;
  }

  .temp84secondimage img {
    width: 100%;
  }


  .temp74 {
    font-size: 24px;
  }

  .maincontentBulletwhite ul {
    padding-left: 0px;
  }

  .mleftmin40 {
    margin-left: 0px;
  }

  .mrightmin40 {
    margin-right: 0px;
  }

  .tempNo_32 .brownTxtHeader,
  .tempNo_33 .brownTxtHeader {
    padding: 24px 8px;
  }

  .temp62img {
    margin-top: 0px;
  }

  .temp62firsthalf {
    height: auto;
  }

  .tempNo_7 {
    margin-bottom: 24px;
  }

  .tempNo_32 .responsiveImage {
    margin-bottom: 16px;
  }

  .tempNo_32 {
    margin-bottom: 24px;
  }

  .temp62secondhalf {
    height: auto;
    padding-top: 16px;
  }

  .tempNo_62 .col4 {
    margin-bottom: 24px;
  }

  .tempNo_33 .col4 {
    margin-bottom: 24px;
  }

  .temp76 {
    text-align: center;
  }

  .contactmeButton {
    position: unset;
    margin: 0;
    float: unset;
    display: inline-block;
  }

  .tempNo_36 .bgCover {
    text-align: center;
  }

  .tempNo_80 .dispayFlex.mtop50.fullWidthcontiner {
    justify-content: center;
  }

  .tempNo_81 .dispayFlex.mtop50.fullWidthcontiner {
    justify-content: center;
    padding-bottom: 40px;
  }

  .tempNo_82 .dispayFlex.mtop50.fullWidthcontiner {
    justify-content: center;
  }

  .temp81cilcleImg3 {
    margin: auto;
  }

  .tempNo_36 .bgCover.h600 {
    height: unset !important;
  }

  .tempNo_80 .col4.responsiveImage.obFit.marBottom {
    margin-bottom: 60px !important;
  }

  .tempNo_81 .col4.responsiveImage.obFit.marBottom {
    margin-bottom: 60px !important;
  }

  .mb50 {
    margin-bottom: 24px;
  }

}


@media (min-width: 992px) {
  .col3 {
    width: 25%;
  }

}

@media only screen and (min-width: 1367px) and (max-device-width: 1518px) {
  .cilcleImg img {
    width: 250px;
    height: 250px;
  }
}

@media only screen and (min-width: 901px) and (max-width: 1366px) {
  .cilcleImg img {
    width: 250px;
    height: 250px;
  }

  .loveJoyfont2 {
    font-size: 70px;
  }
}
